import PropTypes from 'prop-types'
import React from 'react'

import { OpenHouseWhiteUrl } from '../../Svg'

const OpenHouse = ({ closestOpenHouseDate, closestOpenHouseTime }) => {
  return (
    <div className='OpenHouseCard'>
      <div className='OpenHouseCard-items'>
        <div className='OpenHouseCard-item'>
          <img
            src={OpenHouseWhiteUrl}
            className='OpenHouseCard-icon'
            alt='Open house'
          />
          <div className='OpenHouseCard-info OpenHouseCard-info--date'>
            {closestOpenHouseDate}
          </div>
          <div className='OpenHouseCard-info'>
            {closestOpenHouseTime}
          </div>
        </div>
      </div>
    </div>
  )
}

OpenHouse.propTypes = {
  closestOpenHouseDate: PropTypes.string.isRequired,
  closestOpenHouseTime: PropTypes.string.isRequired
}

export default OpenHouse
